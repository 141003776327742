export const addSupportLinks = (supportButtons: NodeListOf<Element>) => {
  if (supportButtons) {
    supportButtons.forEach((button: Element) => {
      button.addEventListener("click", () => {
        if ("fcWidget" in window) {
          //@ts-ignore
          window.fcWidget.open();

          return false;
      }

      return true;
      });
    });
  }
};
