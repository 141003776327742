import { liveRatesApiUrl } from "../constants";
import { RatesApiResponse } from "./types";

let cashRates: RatesApiResponse;

const fetchCashRates = async (
  callback: (args: RatesApiResponse) => void
) => {
  if (cashRates) {
    return callback(cashRates);
  }

  await fetch(`${liveRatesApiUrl}/rates/cash`)
    .then((res) => res.json())
    .then((response) => {
      cashRates = {
        published: response.published,
        rates: response.rates[0].to
      };

      callback(cashRates);
    });
};



export default fetchCashRates;
