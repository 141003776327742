export const formatNumber = (input: number, decimalPlaces: number = 4) => {
  const value = parseFloat(`${input}`).toFixed(decimalPlaces);
  const [intNumber, decimals] = value.replace(/,/g, "").split(".");

  let formattedNumber = "";
  for (let i = 0; i < intNumber.length; i++) {
    formattedNumber += intNumber[i];

    if ((intNumber.length - i) % 3 === 1) {
      formattedNumber += ",";
    }
  }

  if (formattedNumber.charAt(formattedNumber.length - 1) === ",") {
    formattedNumber = formattedNumber.slice(0, -1);
  }

  if (decimals !== undefined) {
    formattedNumber += `.${decimals}`;
  }

  return formattedNumber;
};
