import { liveRatesApiUrl } from "../constants";
import { RatesApiResponse } from "./types";

const fetchLiveRates = async (
  callback: (args: RatesApiResponse) => void
) => {
  await fetch(`${liveRatesApiUrl}/rates/card`)
    .then((res) => res.json())
    .then((response) => callback({
      rates: response.rates[0].to
    }));
};



export default fetchLiveRates;
