import {
	renderLiveRates,
	addSupportLinks,
} from "./utils";

const fairfx = window.location.href.includes("fairfx");

if (fairfx) {
	const supportButtons = document.querySelectorAll(".open-support-chat");

	addSupportLinks(supportButtons);

	renderLiveRates(document);
}
