import fetchCashRates from "../api/cashrates";
import fetchLiveRates from "../api/liverates";
import { RatesApiResponse } from "../api/types";
import { GBP_ERROR } from "../constants";
import { IRate } from "../types";
import { formatDate } from "./formatDate";
import { formatNumber } from "./formatNumber";

const CURRENCY_NAME_NODE_SELECTOR = '.ccy-div > div';

const renderRate = (rate: IRate, type: 'buy' | 'sell') => {
  return type === 'sell' ? '' : formatNumber(rate[type]);
}

const renderAvailableRate = (type: 'buy' | 'sell', rateNode: Element | null, rate: IRate) => {
  if (rateNode && rate) {
    if (rate.error === GBP_ERROR) {
      rateNode.classList.add('available');
      rateNode.innerHTML = '1.0000';

      return;
    }

    const rateAvailable = rate[type] && rate.available && rate.available[type];

    if (!rateAvailable) {
      rateNode.classList.add('unavailable');
    } else {
      rateNode.classList.add('available');
    }

    rateNode.innerHTML = rateAvailable ? renderRate(rate, type) : 'Currently unavailable';
  } else if (rateNode && !rate) {
    rateNode.classList.add('unavailable');
    rateNode.innerHTML = 'Currently unavailable';
  }
}

const renderBuyRates = (node: Element, rate: IRate) => {
  const rateBuyNode = node.querySelector('.currency-buy-value') || node.querySelector('.currency-value');

  renderAvailableRate('buy', rateBuyNode, rate);
}

const renderSellRates = (node: Element, rate: IRate) => {
  const rateSellNode = node.querySelector('.currency-sell-value');

  renderAvailableRate('sell', rateSellNode, rate);
}

export const renderLiveRates = (document: Document) => {
  let publishDate: string | undefined = '';
  const currenciesElements: NodeListOf<Element> = document.querySelectorAll(
    ".currency-grid > div"
  );
  const currencies: string[] = [];

  const renderFunction = window.location.pathname.includes('currency-card') ? fetchLiveRates : fetchCashRates;

  if (currenciesElements) {
    currenciesElements.forEach((node: Element) => {
      const ccyNameNode = node.querySelector(CURRENCY_NAME_NODE_SELECTOR);

      if (!currencies.includes((ccyNameNode as HTMLElement).innerText)) {
        currencies.push((ccyNameNode as HTMLElement).innerText);
      }
    });

    const fetchRates = async () => {
      await renderFunction(({ rates, published }: RatesApiResponse) => {
        publishDate = published;
        currenciesElements.forEach((node: Element) => {
          const ccyNameNode = node.querySelector(CURRENCY_NAME_NODE_SELECTOR);
          const ccyName = ccyNameNode?.innerHTML || '';
          let rate = rates[ccyName];

          renderBuyRates(node, rate);
          renderSellRates(node, rate);
        });
      });

      const updateElements = document.querySelectorAll('#currency-updated-time');

      updateElements.forEach(updateElement => {
        if (updateElement) {
          updateElement.innerHTML = `Rates last updated on ${formatDate(publishDate ? new Date(publishDate) : undefined)}`;
        }
      })
    }

    fetchRates();

    setInterval(fetchRates, 60000);
  }
};
