export const RECAPTCHA_SECRET_KEY = "6Le2ZzcaAAAAAMqVkg63If-6jSbTVmOgis0fG_Vf";
export const gbp = {
	sell: 1,
	buy: 1,
	floor: 1,
	spread: 1,
	available: {
		buy: true,
		sell: true,
	},
};

export const GBP_ERROR = "Cannot convert GBP into GBP";

export const trustPilotApiUrl =
	"https://api.trustpilot.com/v1/business-units/4bdc27ca0000640005059348";

export const trustPilotApiKey = "JHC9hfuc6U3r7DygAjKdYQvwvFCnAPlx";

export const liveRatesApiUrl = window.location.href.includes("fairfx.com")
	? "https://api.fairfx.io/website-proxy"
	: "https://develop-api.fairfx.io/website-proxy";

export const equalsOnboardingApiUrl = window.location.href.includes(
	"equalsmoney.com"
)
	? "https://api.fairfx.io/onboarding/hubSpot"
	: "https://develop-api.fairfx.io/onboarding/hubSpot";

interface CurrencySymbolMap {
	[key: string]: string;
}

export const currencySymbolMap: CurrencySymbolMap = {
	AED: "د.إ",
	AFN: "؋",
	ALL: "L",
	AMD: "֏",
	ANG: "ƒ",
	AOA: "Kz",
	ARS: "$",
	AUD: "$",
	AWG: "ƒ",
	AZN: "ман",
	BAM: "KM",
	BBD: "$",
	BDT: "৳",
	BGN: "лв",
	BHD: ".د.ب",
	BIF: "FBu",
	BMD: "$",
	BND: "$",
	BOB: "$b",
	BRL: "R$",
	BSD: "$",
	BTC: "฿",
	BTN: "Nu.",
	BWP: "P",
	BYR: "p.",
	BZD: "BZ$",
	CAD: "$",
	CDF: "FC",
	CHF: "CHF",
	CLP: "$",
	CNY: "¥",
	COP: "$",
	CRC: "₡",
	CUC: "$",
	CUP: "₱",
	CVE: "$",
	CZK: "Kč",
	DJF: "Fdj",
	DKK: "kr",
	DOP: "RD$",
	DZD: "دج",
	EEK: "kr",
	EGP: "£",
	ERN: "Nfk",
	ETB: "Br",
	ETH: "Ξ",
	EUR: "€",
	FJD: "$",
	FKP: "£",
	GBP: "£",
	GEL: "₾",
	GGP: "£",
	GHC: "₵",
	GHS: "GH₵",
	GIP: "£",
	GMD: "D",
	GNF: "FG",
	GTQ: "Q",
	GYD: "$",
	HKD: "$",
	HNL: "L",
	HRK: "kn",
	HTG: "G",
	HUF: "Ft",
	IDR: "Rp",
	ILS: "₪",
	IMP: "£",
	INR: "₹",
	IQD: "ع.د",
	IRR: "﷼",
	ISK: "kr",
	JEP: "£",
	JMD: "J$",
	JOD: "JD",
	JPY: "¥",
	KES: "KSh",
	KGS: "лв",
	KHR: "៛",
	KMF: "CF",
	KPW: "₩",
	KRW: "₩",
	KWD: "KD",
	KYD: "$",
	KZT: "₸",
	LAK: "₭",
	LBP: "£",
	LKR: "₨",
	LRD: "$",
	LSL: "M",
	LTC: "Ł",
	LTL: "Lt",
	LVL: "Ls",
	LYD: "LD",
	MAD: "د.م.",
	MDL: "lei",
	MGA: "Ar",
	MKD: "ден",
	MMK: "K",
	MNT: "₮",
	MOP: "MOP$",
	MUR: "₨",
	MVR: "Rf",
	MWK: "MK",
	MXN: "$",
	MYR: "RM",
	MZN: "MT",
	NAD: "$",
	NGN: "₦",
	NIO: "C$",
	NOK: "kr",
	NPR: "₨",
	NZD: "$",
	OMR: "﷼",
	PAB: "B/.",
	PEN: "S/.",
	PGK: "K",
	PHP: "₱",
	PKR: "₨",
	PLN: "zł",
	PYG: "Gs",
	QAR: "﷼",
	RMB: "￥",
	RON: "lei",
	RSD: "Дин.",
	RUB: "₽",
	RWF: "R₣",
	SAR: "﷼",
	SBD: "$",
	SCR: "₨",
	SDG: "ج.س.",
	SEK: "kr",
	SGD: "$",
	SHP: "£",
	SLL: "Le",
	SOS: "S",
	SRD: "$",
	SSP: "£",
	STD: "Db",
	SVC: "$",
	SYP: "£",
	SZL: "E",
	THB: "฿",
	TJS: "SM",
	TMT: "T",
	TND: "د.ت",
	TOP: "T$",
	TRL: "₤",
	TRY: "₺",
	TTD: "TT$",
	TVD: "$",
	TWD: "NT$",
	TZS: "TSh",
	UAH: "₴",
	UGX: "USh",
	USD: "$",
	UYU: "$U",
	UZS: "лв",
	VEF: "Bs",
	VND: "₫",
	VUV: "VT",
	WST: "WS$",
	XAF: "FCFA",
	XBT: "Ƀ",
	XCD: "$",
	XOF: "CFA",
	XPF: "₣",
	YER: "﷼",
	ZAR: "R",
	ZWD: "Z$",
};
